<template>
  <div class="input-mask-with-tooltip main-wrapper">
    <div class="label-wrapper">
      <p class="label">{{ label }}</p>
      <XeTooltip activation="click" side="bottom">
        <template #wrappedElement>
          <AppIcon>
            <IconInformation />
          </AppIcon>
        </template>
        <template #tooltipContent>
          <p class="rule-list-item-text">{{ tooltipText }}</p>
        </template>
      </XeTooltip>
    </div>
    <AppInputMask
      v-model="model"
      data-testid="xe-add-recipient-tax-registration-number"
      :mask="mask"
      :placeholder="placeholder"
      :validation="validation"
      class="tax-registration-number"
      @input="onInput"
    >
    </AppInputMask>
  </div>
</template>

<script>
import { useI18nStore } from '@galileo/stores'
import { ref, toRef } from '@vue/composition-api'
import { required } from '@vuelidate/validators'
import { usePromiseLazy, useValidation } from 'vue-composable'

import XeTooltip from '@galileo/components/XeTooltip/XeTooltip'
import { useVModel, AppInputMask, AppIcon } from '@oen.web.vue2/ui'
import { IconInformation } from '@oen.web.vue2/icons'

export default {
  name: 'XeInputMaskWithTooltip',
  components: {
    XeTooltip,
    AppInputMask,
    AppIcon,
    IconInformation,
  },
  emits: ['input'],
  props: {
    value: {
      type: String,
      default: () => {},
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
      required: false,
    },
    mask: {
      type: String,
      default: '',
      required: true,
    },
    tooltipText: {
      type: String,
      default: '',
      required: true,
    },
    validation: {
      type: Object,
      default: null,
      required: false,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const inputRef = ref(null)
    const model = ref(props.value)

    const onInput = (value) => {
      emit('input', value)
    }

    return {
      model,
      $t,
      inputRef,
      onInput,
    }
  },
}
</script>
<style scoped>
.input-mask-with-tooltip {
  .label-wrapper {
    @apply flex items-end;

    .label {
      font-size: 1rem;
    }
  }

  .tooltip-wrapper {
    height: 24px;
    bottom: 1px;
    left: 3px;

    svg {
      color: #636e82 !important;
    }
  }
}
</style>
